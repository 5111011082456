import React, {useEffect, useState} from "react";
import { BsKey, BsEye, BsFullscreen, BsArrowRight } from "react-icons/bs";
import { LiaBedSolid } from "react-icons/lia";
import AOS from "aos";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../config";

const HomeSelection = () => {
const [listings, setListings] = useState([]);
useEffect(() => {
  AOS.init({ duration: 2000 });

  //fetch(`${BASE_URL}/api/getFeaturedListings`)
  fetch('https://api4.promittoltd.com/houses/done')
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.length > 0) {
        setListings(data.slice(0, 4));
      } else {
        //alert("Error");
      }
    })
    .catch((error) => console.log(error));

}, []);
  return (
    <section class="mx-4 lg:mx-16 bg-white dark:bg-gray-900">
      <div class="mt-0 flex-col lg:grid lg:grid-cols-4 gap-8" data-aos="fade-in">

        {
            !listings ? ("No data found"):(
              listings.map((listing) =>(
                <div key={listing.id} class="flex flex-col mx-auto m-10 w-full max-w-lg overflow-hidden rounded-[20px] bg-white shadow-md border border-[#F2B807]">
          <a href="#">
          <img
            class="h-60 rounded-t-lg object-cover w-[100%] sm:h-80 lg:h-60"
            src={listing.images?.[0] || "fallback-image-url.jpg"}
            alt="Property Image"
          />

          </a>

          <h3 class="mt-4 -mb-4 ml-4 text-l text-weight-500 font-lufga">
          {listing.type}
          </h3>

          <div class="m-4 flex justify-between border-b py-8 text-sm sm:text-base">
            <div class="flex items-center">
              <BsFullscreen className="text-black text-xl" />
              <p class="text-slate-500 text-sm font-light ml-1 font-lufga">
              {listing.size}
              </p>
            </div>

            <div class="flex items-center">
              <BsKey className="text-black text-xl" />
              <p class="text-slate-500 text-sm font-light ml-1 font-lufga">
                {listing.type}
              </p>
            </div>

            <div class="flex items-center">
              <LiaBedSolid className="text-black text-xl" />
              <p class="text-slate-500 text-sm font-light ml-1 font-lufga">
              {listing.bedrooms} Bedrooms
              </p>
            </div>
          </div>
          <div class="flex items-center justify-between m-4">
            <Link
            to="#"
              class="flex items-center rounded-md bg-[#F2B807] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
            >
              View Property <BsEye className="text-gray-100 ml-2" />
            </Link>
            <div class="flex flex-col items-left">
              <p class="text-slate-700 mb-1 text-xl font-extrabold font-lufga">
                Pay
              </p>
              <p class="text-slate-500 text-sm font-medium font-lufga">
              KSH {Number(listing.price).toLocaleString()} per month
              </p>
            </div>
          </div>
          <br />
        </div>
              ))
            )


          }

        {/* <!-- Card B--> */}
        {/* <div class="flex flex-col mx-auto m-10 w-full max-w-lg overflow-hidden rounded-[20px] bg-white shadow-md border border-[#F2B807]">
          <a href="#">
            <img
              class="h-60 rounded-t-lg object-cover w-[100%] sm:h-80 lg:h-60"
              src={home2}
            />
          </a>

          <h3 class="mt-4 -mb-4 ml-4 text-l text-weight-500 font-lufga">
            4 Bedroom High-End Mansion
          </h3>

          <div class="m-4 flex justify-between border-b py-8 text-sm sm:text-base">
            <div class="flex items-center">
              <BsFullscreen className="text-black text-xl" />
              <p class="text-slate-500 text-sm font-light ml-1">95 Sqr Mtrs</p>
            </div>

            <div class="flex items-center">
              <BsKey className="text-black text-xl" />
              <p class="text-slate-500 text-sm font-light ml-1 font-lufga">
                For sale only
              </p>
            </div>

            <div class="flex items-center">
              <LiaBedSolid className="text-black text-xl" />
              <p class="text-slate-500 text-sm font-light ml-1 font-lufga">
                4 Bedrooms
              </p>
            </div>
          </div>
          <div class="flex items-center justify-between m-4">
            <a
              href="#"
              class="flex items-center rounded-md bg-[#F2B807] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
            >
              View Property <BsEye className="text-gray-100 ml-2" />
            </a>
            <div class="flex flex-col items-left">
              <p class="text-slate-700 mb-1 text-xl font-extrabold font-lufga">
                Pay
              </p>
              <p class="text-slate-500 text-sm font-medium font-lufga">
                164,105/= per month
              </p>
            </div>
          </div>
        </div> */}

        {/* <!-- Card C--> */}
        {/* <div class="flex flex-col mx-auto m-10 w-full max-w-lg overflow-hidden rounded-[20px] bg-white shadow-md border border-[#F2B807]">
          <a href="#">
            <img
              class="h-60 rounded-t-lg object-cover w-[100%] sm:h-80 lg:h-60"
              src={home3}
            />
          </a>

          <h3 class="mt-4 -mb-4 ml-4 text-l text-weight-500 font-lufga">
            2 Bedroom Bungalow (Flat-roof)
          </h3>

          <div class="m-4 flex justify-between border-b py-8 text-sm sm:text-base">
            <div class="flex items-center">
              <BsFullscreen className="text-black text-xl" />
              <p class="text-slate-500 text-sm font-light ml-1 font-lufga">
                95 Sqr Mtrs
              </p>
            </div>

            <div class="flex items-center">
              <BsKey className="text-black text-xl" />
              <p class="text-slate-500 text-sm font-light ml-1 font-lufga">
                For sale only
              </p>
            </div>

            <div class="flex items-center">
              <LiaBedSolid className="text-black text-xl" />
              <p class="text-slate-500 text-sm font-light ml-1 font-lufga">
                2 Bedrooms
              </p>
            </div>
          </div>
          <div class="flex items-center justify-between m-4">
            <a
              href="#"
              class="flex items-center rounded-md bg-[#F2B807] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
            >
              View Property <BsEye className="text-gray-100 ml-2" />
            </a>
            <div class="flex flex-col items-left">
              <p class="text-slate-700 mb-1 text-xl font-extrabold font-lufga">
                Pay
              </p>
              <p class="text-slate-500 text-sm font-medium font-lufga">
                29,125/= per month
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default HomeSelection;
