import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import axios from 'axios';
import { FaUserTie, FaHandHoldingHeart } from 'react-icons/fa';
import { BiTrendingUp } from 'react-icons/bi';

const iconMap = [FaHandHoldingHeart, FaUserTie, BiTrendingUp];

const WhyChooseUs = () => {
    const [data, setData] = useState({ description: '', entries: [] });

    useEffect(() => {
        AOS.init({ duration: 2000 });
        axios.get('https://api4.promittoltd.com/choose-us')
            .then(response => {
                setData({
                    description: response.data.description,
                    entries: response.data.entries.slice(0, 3) // Only first three entries
                });
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <section className="bg-white dark:bg-gray-900">
            <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
                <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                    <h2 className="mb-4 text-4xl font-bold tracking-wide text-[#F2B807] dark:text-white font-lufga" data-aos="fade-down">
                        {data.description || 'Why Choose Us'}
                    </h2>
                </div>
                <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                    {data.entries.map((entry, index) => {
                        const Icon = iconMap[index % iconMap.length];
                        return (
                            <div key={entry.id} className="flex flex-col w-full p-6 mx-auto text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white" data-aos="fade-up">
                                <div className="flex items-left justify-left mb-4">
                                    <div className="w-16 h-16 bg-[#F2B807] rounded-full flex items-center justify-center">
                                        <Icon className="text-black text-2xl" />
                                    </div>
                                </div>
                                <h3 className="mb-4 text-xl font-semibold font-lufga">{entry.title}</h3>
                                <p className="text-gray-600 sm:text-[16px] dark:text-gray-400 font-lufga">{entry.explanation}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default WhyChooseUs;
