import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import Slider from "react-slick";
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";

export default function Testimonials() {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    AOS.init({ duration: 2000 });

    // Fetch testimonials from API
    axios.get("https://api4.promittoltd.com/testimonials")
      .then((response) => {
        setTestimonials(response.data);
      })
      .catch((error) => console.error("Error fetching testimonials:", error));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
        <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
          <h2 className="mb-4 text-4xl font-bold tracking-wide font-lufga text-[#F2B807] dark:text-white" data-aos="fade-down">
            Testimonials
          </h2>
          <p className="mb-5 font-lufga text-gray-600 sm:text-[16px] dark:text-gray-400" data-aos="fade-down">
            From Dream to Reality, Hear What Our Satisfied Clients Have to Say.
          </p>
        </div>

        {testimonials.length > 0 ? (
          <Slider {...settings} className="px-2">
          {testimonials.map((item) => (
            <div key={item.id} className="px-3">
              <div className="flex flex-col w-full h-72 text-left text-gray-900 bg-white border border-gray-100 rounded-[20px] shadow dark:border-gray-600 dark:bg-gray-800 dark:text-white p-6">
                <div className="flex items-center gap-8">
                  <div className="w-12 h-12 border-gray-100 shadow dark:border-gray-600 rounded-full flex items-center justify-center overflow-hidden">
                    <FaUserCircle className="text-gray-500 text-3xl" />
                  </div>
                  <div>
                    <h2 className="text-lg font-semibold font-lufga">{item.name}</h2>
                    <p className="text-gray-600 text-[14px] font-medium font-lufga">{item.role}</p>
                  </div>
                </div>
                <br />
                <h3 className="text-[16px] font-semibold font-lufga">{item.rating}</h3>
                <p className="text-gray-600 sm:text-[16px]  dark:text-gray-400 font-lufga">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </Slider>
    
        ) : (
          <p className="text-center text-gray-500">Loading testimonials...</p>
        )}
      </div>
    </section>
  );
}
